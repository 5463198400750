import React, {useState} from 'react';
import { useGoogleReCaptcha} from "react-google-recaptcha-v3"
import axios from "axios";

export default function ContactForm(props){
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
  
    const handleSubmit = async(e) => {
      e.preventDefault()
  
      // get captcha token
      const token = await executeRecaptcha('ContactForm')
  
      let fd = new FormData()
      fd.append('name', name)
      fd.append('email', email)
      fd.append('message', message)
      fd.append("captchaToken", token)
      
      await axios.post(process.env.API_URL + "/contact", fd, { headers: {"Content-type": "application/json"} });

      // mark sent to parent
      props.setSent(true);

    }

    return (

        <form onSubmit={handleSubmit} className="mt-12 ">
            <div className="space-y-4 mx-auto w-1/2">
                <div className=''>
                <label for="" className="text-base font-medium text-gray-900">
                    Your name
                </label>
                <div className="mt-2.5">
                    <input type="text" name='name' onChange={(e) => setName(e.target.value)} required={true} className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900" />
                </div>
                </div>

                <div >
                    <label for="" className="text-base font-medium text-gray-900">
                    Your email
                    </label>

                <div className="mt-2.5">
                    <input type="email" name='email' onChange={(e) => setEmail(e.target.value)} required={true} className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900" />
                </div>
                </div>

                <div>
                    <label for="" className="text-base font-medium text-gray-900">
                    Message
                    </label>
                    <div className="mt-2.5">
                    <textarea onChange={(e)=> setMessage(e.target.value)} required={true} rows={5} className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900" />
                    </div>
                </div>

                <button type="submit" className="px-8 py-4 mt-5 font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-600">
                    Send
                </button>

            </div>
        
        </form>

    );    

} 