import React, {useRef, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"
import { GoogleReCaptchaProvider} from "react-google-recaptcha-v3"
import ContactForm from '../components/ContactForm';



export default function Contact () {

    const [sent, setSent] = useState(false);

    return (

        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us | Skillmeter</title>
            <meta name="description" content="Get in touch with Skillmeter on this page" />
            <meta name="keywords" content="candidate testing, online testing, online assessment, skillmeter contact" />

        </Helmet>

        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
          <div className="px-4 mx-auto sm:px-6 lg:px-8">
            <div className="px-8 mx-auto text-center md:px-0">
              <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
                Contact Us
              </h2>
              <p className="mt-6 text-lg font-normal text-gray-600 ">
              We are here to help.
              </p>
            </div>

            {/* Form goes here*/}
            <div className="max-w-5xl mx-auto mt-10 sm:mt-20 justify-center">
              <p className='text-lg font-normal w-1/2 mx-auto'>
                Have a question about Skillmeter or your account?
                <br />
                Complete the form below and we'll get in touch asap.
              </p>
              <GoogleReCaptchaProvider reCaptchaKey="6Ldz-VAfAAAAAGFLDhsmuq-w4_AF_RlyXCneIR8q">
                {sent ? 
                  <div className="mt-12 rounded-md bg-green-50 border-2 border-green-600 p-4 w-1/2 mx-auto">
                      <div className="">
                        <p className="text-lg font-medium text-green-800">🙏 Thank you, we received your message!</p>
                      </div>
                  </div>
                  :
                  <ContactForm setSent={setSent}/>
                }
                
              </GoogleReCaptchaProvider>
            </div>

          </div>
        </section>





        <Footer />
        </>
    );
}